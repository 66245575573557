define(['app', 'leaflet', '$window', 'siteObj'], (app, L, window, siteObj) => {

  const locator = () => {
    const DEFAULT_MAP_CENTER_LONGITUDE = -2.3234862113659;
    const DEFAULT_MAP_CENTER_LATITUDE = 53.494013201943;

    const component = {};
    component.app = app;

    const _config = {
      header: 'header',
      stripBanner: '.stripBanner',
      wrapper: '[data-js-element=locatorCard_wrapper]',
      mapId: 'storelocator',
      mapLayer: '[data-js-element=locatorMap]',
      locatorCard: '[data-js-element=locatorCard]',
      cardHeaderMarker: '[data-js-element=locatorCard_header_marker]',
      cardHeaderbeforeSearch: 'locatorCard_header_marker-beforeSearch',
      distanceHeader: '[data-js-element=locatorCard_header_distance]',
      distanceHeaderbeforeSearch: 'locatorCard_header_distance-beforeSearch',
      markerHeader: '[data-js-element=locatorCard_header_marker]',
      markerHeaderbeforeSearch: 'locatorCard_header_marker-beforeSearch',
      mapError: 'locatorMap_error',
      latLong: '[data-latLong]',
      centerCoord: '[data-center]',
      locatorCardOpen: 'locatorCard-open',
      markerIcons: '.locatorMap_customMarker',
      locatorShow: 'locator-show',
      locatorMapDone: 'locatorMap-done',
      selectedStoreLongitude: 'selectedStoreLongitude',
      selectedStoreLatitude: 'selectedStoreLatitude',
      customIcon: {
        currentLocation: 'locatorMap_currentLocation',
        beforeSearch: 'locatorMap_customMarker',
        afterSearch: 'locatorMap_customMarker-afterSearch',
        iconSize_width: Object.prototype.hasOwnProperty.call(siteObj, 'storeLocator') && Object.prototype.hasOwnProperty.call(siteObj.storeLocator, 'locatorMapIconWidth') ? siteObj.storeLocator.locatorMapIconWidth : 40,
        iconSize_height: Object.prototype.hasOwnProperty.call(siteObj, 'storeLocator') && Object.prototype.hasOwnProperty.call(siteObj.storeLocator, 'locatorMapIconHeight') ? siteObj.storeLocator.locatorMapIconHeight : 40,
        iconAnchor_width: Object.prototype.hasOwnProperty.call(siteObj, 'storeLocator') && Object.prototype.hasOwnProperty.call(siteObj.storeLocator, 'locatorMapAnchorWidth') ? siteObj.storeLocator.locatorMapAnchorWidth : 20,
        iconAnchor_height: Object.prototype.hasOwnProperty.call(siteObj, 'storeLocator') && Object.prototype.hasOwnProperty.call(siteObj.storeLocator, 'locatorMapAnchorHeight') ? siteObj.storeLocator.locatorMapAnchorHeight : 40,
      },
      alternateLocator: 'alternateLocator'
    };
    const _init = (element) => {
      component.element = element;
      component.markers = [];
      if (component.element.classList.contains('alternateLocator')) {
        let longitude = window.localStorage.getItem(component.config.selectedStoreLongitude);
        let latitude = window.localStorage.getItem(component.config.selectedStoreLatitude);
        if (latitude === null || longitude === null || latitude === '' || longitude === '') {
          latitude = DEFAULT_MAP_CENTER_LATITUDE;
          longitude = DEFAULT_MAP_CENTER_LONGITUDE;
        }

        app.ajax.get({
          url: `/getNearestStoresLocatorCardXHR.lookup?lon=${longitude}&lat=${latitude}`,
          success: component.mapPins,
          error:  () => { 
            app.console.error('Error in getting nearest stores');
          }
        });
      } else {
        component.completeInit();
      }

      return component;
    };

    const _completeInit = () => {
      let callbackName = component.JSONP(component.initMap);
      component.elements();
      component.loadMapsAPI(`https://maps.googleapis.com/maps/api/js?key=AIzaSyDAe9q-8nn_s6GdxTOsV1mI_JYpQR7wKuw&callback=${callbackName}&libraries=places`);
      app.subscribe('_locator', component.searchResults);
      app.subscribe('_locator/locatorMarker', component.subscribeGoToMarker);
      app.subscribe('_locator/currentLocation', component.subscribeCurrentLocation);
    };

    const _elements = () => {
      component.isMobile = !L.Browser.mobile;
      component.mapTile = document.querySelector(component.config.mapLayer);
      component.header = document.querySelector(component.config.header);
      component.stripBanner = document.querySelector(component.config.stripBanner);
      component.headerHeight = component.header.clientHeight;
      component.useAlternateLocator = component.element.classList.contains(component.config.alternateLocator);
      if (component.stripBanner) {
        component.headerHeight += component.stripBanner.clientHeight;
      }
      component.element.style.height = `calc(100vh - ${component.headerHeight}px)`;
    };

    const _mapPins = (response) => {
      document.querySelector(component.config.locatorCard).outerHTML = response;
      component.completeInit();
    }

    const _subscribeGoToMarker = (markerCoord) => {
      component.goToMarker(markerCoord);
    };

    const _subscribeCurrentLocation = (currentCoords) => {
      component.currentLocation(currentCoords);
    };

    const _JSONP = (callback) => {
      let timestamp = Date.now();
      let generatedFunctionName = 'jsonp' + Math.round(timestamp + Math.random() * 1000001);

      window[generatedFunctionName] = () => {
        callback();
        window[generatedFunctionName] = undefined;
      };

      setTimeout(() => {
        if (window[generatedFunctionName]) {
          window[generatedFunctionName] = undefined;
          component.errorMessage();
        }
      }, 5000);

      return generatedFunctionName;
    };

    const _errorMessage = () => {
      app.element.addClass(component.config.mapError, component.mapTile);
      component.mapTile.innerHTML = `<div class="locatorMap_error_message">Sorry, Something went wrong
                                        </div>
                                        <div class="locatorMap_error_refresh">Please try refreshing the page</div>`;
    };

    const _loadMapsAPI = (url) => {
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.setAttribute('async', '');
      script.setAttribute('defer', '');
      script.src = url;
      document.body.appendChild(script);
    };

    const _initMap = () => {
      component.searchResult = false;
      if(component.useAlternateLocator &&
        localStorage.getItem(component.config.selectedStoreLongitude) &&
        localStorage.getItem(component.config.selectedStoreLatitude)) {
        component.latlng = [parseFloat(localStorage.getItem(component.config.selectedStoreLatitude)), parseFloat(localStorage.getItem(component.config.selectedStoreLongitude))];
      }
      else {
        let latLong = component.element.querySelectorAll(component.config.latLong)[0].dataset.latlong;

        if (latLong) {
          latLong = latLong.split(',');
          component.latlng = [parseFloat(latLong[0]), parseFloat(latLong[1])];
        } else {
          component.latlng = [DEFAULT_MAP_CENTER_LATITUDE, DEFAULT_MAP_CENTER_LONGITUDE];
        }
      }
      component.storelocator = L.map(component.config.mapId, {
        dragging: component.isMobile,
        tap: component.isMobile,
        minZoom: 3
      }).setView(component.latlng, 8);

      component.getMapStyle('/mapStyling.locator');

      app.publish('locatorCard/searchUrl', null);
      component.loadMarkers();
    };

    const _getMapStyle = (query) => {
      app.ajax.get({
        url: query,
        success: component.onSuccess,
        error: component.onError
      });
    };

    const _onSuccess = (res) => {
      component.jsonObj = JSON.parse(res);
      component.mapStyle = component.jsonObj.mapStyle !== undefined ? component.jsonObj.mapStyle : null;
      app.element.addClass(component.config.locatorMapDone, component.mapTile);
      L.gridLayer.googleMutant({
        type: 'roadmap',
        styles: component.mapStyle
      }).addTo(component.storelocator);
    };

    const _onError = () => {
      app.console.error('Custom map style not loaded, fallback to default google map');
    };

    const _resetMarkers = () => {
      component.markers.forEach(marker => {
        component.storelocator.removeLayer(marker);
      });

      component.markers = [];
    };

    const _loadMarkers = () => {
      component.latLong = component.element.querySelectorAll(component.config.latLong);
      Array.from(component.latLong).forEach((el, index) => {
        component.renderMarker(el, index);
      });
    };

    const _renderMarker = (el, i) => {
      let latlong = app.element.getAttribute('data-latlong', el);
      let markerData = latlong.split(',');

      let icon = L.divIcon({
        className: !component.searchResult ? component.config.customIcon.beforeSearch : component.config.customIcon.afterSearch,
        iconSize: [component.config.customIcon.iconSize_width , component.config.customIcon.iconSize_height],
        bgPos: [0, 0],
        iconAnchor: [component.config.customIcon.iconAnchor_width, component.config.customIcon.iconAnchor_height],
        html: `<div class="locatorMap_marker_number" data-marker="locatorCard-${i + 1}">${component.searchResult ? i + 1 : ''}</div>`
      });

      let marker = L.marker(markerData, {
        icon: icon
      });
      marker.on('click', component.markerEvents);

      component.markers.push(marker);
      marker.addTo(component.storelocator);

      if (component.useAlternateLocator === true) {
        let address = el.querySelector('.locatorCard_content_address_paragraph').innerHTML;
        let name = el.querySelector('.locatorCard_header_title').innerHTML;
        let tel = el.querySelector('.locatorCard_content_link').innerHTML;
        let storeURL = el.querySelector('.locatorCard_content_buttons_learnMore').getAttribute('href');
        let text = '<div class="storeLocator_tooltip">' +
          '  <a class="storeLocator_tooltip_storeName" href="' + storeURL + '">' + name + '</a>' +
          '  <div class="storeLocator_tooltip_storeAddress">' + address + '</div>' +
          '  <div class="storeLocator_tooltip_phoneNumber">' + tel + '</div>' +
          '  <a class="storeLocator_tooltip_button_directions" href="https://www.google.com/maps/dir/current+location/' + latlong + '" target="_blank" rel="noopener">Get Directions</a>' +
          '</div>';
        marker.bindPopup(text);
      }
    };

    const _markerEvents = (e) => {
      let markerCoord = [e.target._latlng.lat, e.target._latlng.lng];

      if (!component.searchResult) {
        app.element.addClass(component.config.locatorShow, component.element);
        let distanceHeader = document.querySelectorAll(component.config.distanceHeader);
        let markerHeader = document.querySelectorAll(component.config.markerHeader);
        Array.from(distanceHeader).forEach(function(el) {
          app.element.addClass(component.config.distanceHeaderbeforeSearch, el);
        });

        Array.from(markerHeader).forEach(function(el) {
          app.element.addClass(component.config.markerHeaderbeforeSearch, el);
        });
      }

      component.toggleAccordion(e);
      component.goToMarker(markerCoord);
      component.centerMap();
    };

    const _toggleAccordion = (e) => {
      let markerNumber = e.target._icon.firstChild;
      let numberHref = app.element.getAttribute('data-marker', markerNumber);
      let locatorCardId = document.querySelector(`#${numberHref}`);
      app.publish('_locatorCards/toggleAccordions', locatorCardId);
      app.publish('_locatorCards', null);
    };

    const _goToMarker = (coord) => {
      component.storelocator.flyTo(coord, 12);
    };

    const _currentLocation = (currentCoords) => {
      let currentIcon = L.divIcon({
        className: component.config.customIcon.currentLocation,
        iconSize: [component.config.customIcon.iconSize_width , component.config.customIcon.iconSize_height],
        bgPos: [0, 0],
        iconAnchor: [component.config.customIcon.iconAnchor_width , component.config.customIcon.iconAnchor_height],
      });

      let currentMarker = L.marker(currentCoords, {
        icon: currentIcon
      });

      currentMarker.addTo(component.storelocator);
    };

    const _searchResults = () => {
      component.searchResult = true;
      component.resetMarkers();
      component.loadMarkers();

      let cardWrapper = document.querySelector(component.config.centerCoord);
      let center = L.marker(cardWrapper.getAttribute('data-center').split(','));
      let noResults = document.querySelector('.locatorCard_noResults');
      if(noResults && component.useAlternateLocator) {
        center = L.marker(51.507, 0.128);
      }
      let group = L.featureGroup(component.markers.concat([center]));

      component.storelocator.fitBounds(group.getBounds(), {
        padding: [150, 100],
        maxZoom: 10
      });
      component.centerMap();

      app.publish('_locatorCards', null);
    };

    const _centerMap = () => {
      setTimeout(function() {
        component.storelocator.invalidateSize(false);
      }, 500);
    };

    component.config = _config;
    component.init = _init;
    component.completeInit = _completeInit;
    component.elements = _elements;
    component.subscribeCurrentLocation = _subscribeCurrentLocation;
    component.currentLocation = _currentLocation;
    component.subscribeGoToMarker = _subscribeGoToMarker;
    component.errorMessage = _errorMessage;
    component.JSONP = _JSONP;
    component.loadMapsAPI = _loadMapsAPI;
    component.getMapStyle = _getMapStyle;
    component.onSuccess = _onSuccess;
    component.onError = _onError;
    component.initMap = _initMap;
    component.loadMarkers = _loadMarkers;
    component.renderMarker = _renderMarker;
    component.resetMarkers = _resetMarkers;
    component.goToMarker = _goToMarker;
    component.markerEvents = _markerEvents;
    component.toggleAccordion = _toggleAccordion;
    component.searchResults = _searchResults;
    component.centerMap = _centerMap;
    component.mapPins = _mapPins;
    return component;
  };

  return locator;
});
